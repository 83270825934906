.page__container {
  width: 100% !important;
  max-width: 1200px !important;
}

.custom__button {
  background-image: linear-gradient(
    136deg,
    rgb(242, 113, 33) 0%,
    rgb(233, 64, 87) 50%,
    rgb(138, 35, 135) 100%
  );
  color: white;
}
