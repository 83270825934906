.message {
  display: flex;
  margin-top: 1rem;
}
.compressed__messages {
  display: flex;
  margin-top: 0.3rem !important;
}
.mine__message {
  flex-direction: row-reverse;
  justify-content: end;
  margin-left: 10%;
}
.notMine__message {
  flex-direction: row;
  justify-content: start;
  margin-right: 10%;
  margin-top: 2rem;
}
.card__content {
  padding: 0.5rem 0.7rem;
  border-radius: 1rem;

  .top__line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title,
  .date,
  .content {
    font-size: 1.1rem;
    margin-top: 0.5rem;
    ol,
    ul {
      margin-left: 1rem;
    }
    a {
      text-decoration: underline;
    }
  }
}
.card__content__mine {
  background-color: #8ed6ec;
  border-top-right-radius: 0;
  max-width: 400px !important;
  .title,
  .date,
  .content {
    color: #444;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    p {
      color: #444;
      font-size: 1rem;
    }
  }
  .date {
    color: #777;
    font-size: 0.8rem;
  }
}
.card__content__notMine {
  background-color: #fff;
  border-top-left-radius: 0;
  max-width: 400px !important;
  .title,
  .date,
  .content {
    color: #000;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    p {
      color: #000;
      font-size: 1rem;
    }
  }
  .date {
    color: #777;
    font-size: 0.8rem;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .check {
    margin-left: 5px;
    font-size: 2rem;
  }
  .check__min {
    color: #fff;
  }
  .check__notMin {
    color: var(--color-secondary);
  }
}
