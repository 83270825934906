.enlarge__btn {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 6;
  height: 1rem;
  width: 1rem;
}
.close__btn {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 10001;
}

.icon {
  font-size: 3rem;
  color: #ccc;
}
.icon__black {
  font-size: 2rem;
  color: #ccc;
}

.full__image {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
  .image__container1 {
    padding-top: 0 !important;
  }
  .image__container {
    position: relative;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    animation: zoomin;
    animation-duration: 0.5s;
    display: grid;
    place-items: center;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}
.checkbox__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.image_checkbox {
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  z-index: 2;
}
.image_checkbox_mobile {
  top: 2rem;
  left: 1rem;
  z-index: 2;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.2);
  input {
    background-color: var(--bs-info) !important;
    border: #ccc !important;
  }
}
@keyframes zoomin {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
