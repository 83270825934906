.activity__card__item {
  animation: all 0.3s ease-in-out;
  //cursor: pointer;
  min-height: 7rem;

  .delete__icon {
    @media (min-width: 900px) {
      display: none;
    }
  }
}

.activity__card__item:hover {
  background-color: transparent !important;
}
