.activity__card__item {
  animation: all 0.3s ease-in-out;
  // cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
    .delete__icon {
      visibility: visible;
    }
  }
  .delete__icon {
    @media (min-width: 900px) {
      visibility: hidden;
    }
  }
}
