.add__btn {
  background-color: #fefefe !important;
  border-radius: 0 !important;
  color: var(--bs-gray-600);
  &:hover {
    background-color: #eee !important;
  }
}
[data-bs-theme='dark'] .add__btn {
  background-color: #2a2a2a !important;
  border-radius: 0 !important;
  color: #ddd;
  &:hover {
    background-color: #333 !important;
  }
}
